<template>
  <el-dialog
    :model-value="modelValue"
    :close-on-click-modal="false"
    width="50%"
    title="资料文件管理"
    @close="closed"
  >
    <el-upload
      class="upload-demo"
      :action="productFileUploadUrl"
      :headers='uploadHeaders'
      :before-upload="beforeFileUpload"
      :on-success="beforeFileSuccess"
      :on-error="beforeFileError"
      :on-preview="handleFilePreview"
      :on-remove="handleFileRemove"
      :before-remove="beforeFileRemove"
      multiple
      :on-exceed="handleFileExceed"
      :file-list="fileList"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <template #tip>
        <div class="el-upload__tip">
          单文件大小限制不可超过10MB
        </div>
      </template>
    </el-upload>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  getProductFileUploadUrl,
  getProductFileList,
  deleteProductFile
} from '@/api/product-file'
import store from '@/store'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  id: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'id', 'close'])

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
}

// 上传携带验证参数
const uploadHeaders = ref({ token: store.getters.token })

/**
 * 产品资料列表
 */
const productFileUploadUrl = ref('')
const fileList = ref([])
const getFileList = async () => {
  // 获取指定产品的资料上传地址
  productFileUploadUrl.value = getProductFileUploadUrl(props.id)
  fileList.value = await getProductFileList(props.id)
}
getFileList()

/**
 * 上传资料前执行
 * @param file
 * @returns {boolean}
 */
const beforeFileUpload = file => {
  const isLt10M = file.size / 1024 / 1024 < 10
  if (!isLt10M) {
    ElMessage.error('文件大小不能超过 10MB!')
  }
  return isLt10M
}

/**
 * 文件上传成功时的钩子
 */
const beforeFileSuccess = (response, file, fileList) => {
  getFileList()
}

/**
 * 文件上传失败时的钩子
 */
const beforeFileError = (response, file, fileList) => {
  getFileList()
}

/**
 * 点击文件列表中已上传的文件时的钩子
 * @param file
 */
const handleFilePreview = file => {
  window.open(file.url, '_blank')
}

/**
 * 文件列表移除文件时的钩子
 * @param file
 * @param fileList
 */
const handleFileRemove = (file, fileList) => {}

/**
 * 删除文件之前的钩子，参数为上传的文件和文件列表。 若返回 false 或者返回 Promise 且被 reject，则终止删除。
 * @param file
 * @param fileList
 */
const beforeFileRemove = (file, fileList) => {
  // 判断是否是有效的删除文件，因为上传失败也会自动删除列表项，导致出发此钩子
  if (!Number.isInteger(file.id)) {
    return true
  }

  const promise = new Promise((resolve, reject) => {
    ElMessageBox.confirm('确认删除该 [' + file.name + '] 文件吗？', '删除', {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(async () => {
        // 从服务器删除文件
        await deleteProductFile(file.id)
        ElMessage({
          type: 'success',
          message: '删除成功'
        })
        resolve()
        getFileList()
      })
      .catch(() => {
        reject(new Error('删除失败'))
      })
  })
  return promise
}

/**
 * 文件超出个数限制时的钩子
 * @param files
 * @param fileList
 */
const handleFileExceed = (files, fileList) => {}
</script>

<style lang="scss" scoped></style>
