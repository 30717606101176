<template>
  <el-dialog
    :model-value="modelValue"
    :close-on-click-modal="false"
    width="50%"
    title="认购金额管理"
    @close="closed"
  >
    <el-table
      border
      v-loading="loading"
      highlight-current-row
      :header-cell-style="{
        background: '#fafafa',
        color: '#333333',
        padding: '12px 0'
      }"
      :cell-style="{ padding: '4px 0' }"
      :data="incomeListData"
      style="width: 100%"
    >
      <el-table-column label="#" width="50" align="center" type="index" />
      <el-table-column property="amount" label="认购金额" />
      <el-table-column property="rate" label="收益值">
        <template #default="scope"> {{ scope.row.rate }}% </template>
      </el-table-column>
      <el-table-column
        property="sortNumber"
        label="排序号"
        width="78"
        align="center"
      />
      <el-table-column label="操作" width="120">
        <template #default="scope">
          <el-button type="text" @click="editIncomeRateClick(scope.row)"
            >修改
          </el-button>
          <el-button
            type="text"
            style="color: #ff4d4f"
            @click="delIncomeRateClick(scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-divider></el-divider>
    <el-form ref="incomeFormRef" :model="incomeForm" :rules="incomeRules">
      <el-col :span="9" style="width: 100%;">
        <el-form-item label="" prop="amount" style="margin-right: 5px">
          <el-input v-model="incomeForm.amount" placeholder="请输入认购金额">
            <template #prepend>认购金额</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="9" style="width: 100%;">
        <el-form-item
          label=""
          prop="rate"
          style="margin-left: 5px;margin-right: 5px"
        >
          <el-input v-model="incomeForm.rate" placeholder="请输入收益值">
            <template #prepend>收益值</template>
            <template #append>%</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6" style="width: 100%;">
        <el-form-item label="" prop="sortNumber" style="margin-left: 5px">
          <el-input v-model="incomeForm.sortNumber" placeholder="请输入排序号">
            <template #prepend>排序号</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="width: 100%;">
        <el-button
          type="primary"
          plain
          @click="addOrEidtIncomeDataClick"
          style="margin-bottom: 20px;width: 100%"
          >{{ showEditIncomeRate ? '确认修改' : '确认添加' }}
        </el-button>
      </el-col>
      <el-col
        :span="24"
        style="width: 100%;text-align: center;"
        v-if="showEditIncomeRate"
      >
        <el-button type="text" @click="hideEditIncomeRate">取消 </el-button>
      </el-col>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  getProductIncomeRateList,
  addProductIncomeRate,
  updateProductIncomeRate,
  deleteProductIncomeRateById
} from '@/api/product-income-rate'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  id: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'id', 'close'])

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
}

const loading = ref(false)

/**
 * 产品收益表单
 */
const incomeFormRef = ref(null)
const incomeForm = ref({
  productId: props.id,
  amount: '',
  rate: '',
  sortNumber: '1'
})
const incomeRules = ref({
  amount: [
    {
      required: true,
      trigger: 'blur',
      message: '产品认购金额不能为空'
    }
  ],
  rate: [
    {
      required: true,
      trigger: 'blur',
      message: '产品收益值不能为空'
    }
  ],
  sortNumber: [
    {
      required: true,
      trigger: 'blur',
      message: '排序号不能为空'
    }
  ]
})

/**
 * 产品收益列表
 */
const incomeListData = ref([])
const getIncomeListData = async () => {
  loading.value = true
  incomeListData.value = await getProductIncomeRateList(props.id)
  loading.value = false
}
getIncomeListData()

/**
 * 添加或修改产品收益点击事件
 */
const showEditIncomeRate = ref(false)
const addOrEidtIncomeDataClick = () => {
  if (!showEditIncomeRate.value) {
    // 添加
    incomeFormRef.value.validate(async valid => {
      if (!valid) return
      await addProductIncomeRate(incomeForm.value)
      ElMessage({
        type: 'success',
        message: '添加成功'
      })
      incomeFormRef.value.resetFields()
      getIncomeListData()
    })
  } else {
    // 修改
    incomeFormRef.value.validate(async valid => {
      if (!valid) return
      await updateProductIncomeRate(incomeForm.value)
      ElMessage({
        type: 'success',
        message: '修改成功'
      })
      hideEditIncomeRate()
      getIncomeListData()
    })
  }
}

/**
 * 修改产品收益信息
 */
const editIncomeRateClick = row => {
  incomeForm.value = JSON.parse(JSON.stringify(row))
  incomeFormRef.value.resetFields()
  showEditIncomeRate.value = true
}
/**
 * 隐藏修改产品收益信息
 */
const hideEditIncomeRate = () => {
  showEditIncomeRate.value = false
  incomeFormRef.value.resetFields()
}

/**
 * 删除产品收益
 */
const delIncomeRateClick = row => {
  ElMessageBox.confirm('确认删除吗？', '删除', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      await deleteProductIncomeRateById(row.id)
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
      getIncomeListData()
    })
    .catch(() => {})
}
</script>

<style lang="scss" scoped></style>
