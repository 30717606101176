import request from '@/utils/request'

/**
 * 获取指定产品的信息列表
 */
export const getProductInfoList = productId => {
  return request({
    url: `/product/info/list/${productId}`,
    method: 'GET'
  })
}

/**
 * 获取指定id的信息列表
 */
export const getProductInfoById = id => {
  return request({
    url: `/product/info/${id}`,
    method: 'GET'
  })
}

/**
 * 添加产品信息
 */
export const addProductInfo = data => {
  return request({
    url: '/product/info',
    method: 'POST',
    data
  })
}

/**
 * 修改产品信息
 */
export const updateProductInfo = data => {
  return request({
    url: '/product/info',
    method: 'PUT',
    data
  })
}

/**
 * 删除产品信息
 * @param id 产品信息id
 * @returns {*}
 */
export const deleteProductInfoById = id => {
  return request({
    url: `/product/info/${id}`,
    method: 'DELETE'
  })
}
