import request from '@/utils/request'

/**
 * 获取全部省数据
 */
export const getAreasProvince = () => {
  return request({
    url: '/areas/getProvince'
  })
}

/**
 * 获取指定省的市级数据
 */
export const getAreasCity = province => {
  return request({
    url: `/areas/getCity/${province}`
  })
}

/**
 * 获取指市的区县数据
 */
export const getAreasCounty = city => {
  return request({
    url: `/areas/getCounty/${city}`
  })
}

/**
 * 获取指定code的数据
 */
export const getAreaByCode = code => {
  return request({
    url: `/areas/code/${code}`
  })
}
