<template>
  <div>
    <el-card>
      <div class="header">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
          <el-form-item label="产品分类">
            <el-select
              v-model="queryForm.categoryId"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in categoryListData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品名称">
            <el-input v-model="queryForm.title" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="发行机构">
            <el-input
              v-model="queryForm.issuer"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox v-model="queryForm.hot" label="热销" />
            <el-checkbox v-model="queryForm.recommend" label="推荐" />
          </el-form-item>
          <el-form-item style="margin-left: 10px;">
            <el-button type="primary" @click="getListData">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div
        class="tool"
        style="background: #fafafa;display: flex;padding: 10px;border: 1px solid #ebeef5;border-bottom: 0;"
      >
        <div>
          <el-button size="small" type="primary" @click="addClick" v-permission="['sys:product:add']"
            >添加产品
          </el-button>
        </div>
        <div style="flex: 1;text-align: right;">
          <el-button size="small" type="primary" @click="getListData" plain
            >刷新列表
          </el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          border
          v-loading="loading"
          :header-cell-style="{
            background: '#fafafa',
            color: '#333333',
            padding: '12px 0'
          }"
          :cell-style="{ padding: '12px 0' }"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column property="id" label="#" width="50" align="center" />
          <el-table-column property="title" label="产品名称" min-width="250" />
          <el-table-column property="categoryId" label="产品分类" width="80">
            <template #default="scope">
              <template v-for="item in categoryListData">
                <template v-if="item.id == scope.row.categoryId">
                  {{ item.name }}
                </template>
              </template>
            </template>
          </el-table-column>
          <el-table-column property="issuer" label="发行机构" />
          <el-table-column property="status" label="产品状态" width="80">
            <template #default="scope">
              <span v-if="scope.row.status == 1">在售</span>
              <span v-else-if="scope.row.status == 2">已售完</span>
              <span v-else-if="scope.row.status == 3">已下架</span>
            </template>
          </el-table-column>
          <el-table-column property="term" label="最高收益" width="80">
            <template #default="scope"> {{ scope.row.income }}% </template>
          </el-table-column>
          <el-table-column property="term" label="产品期限" width="80">
            <template #default="scope"> {{ scope.row.term }}个月 </template>
          </el-table-column>
          <el-table-column property="scale" label="募集规模" width="80" />
          <el-table-column property="rating" label="产品评级" width="146">
            <template #default="scope">
              <el-rate v-model="scope.row.rating" allow-half disabled />
            </template>
          </el-table-column>
          <el-table-column property="sortNumber" label="排序号" width="70" />
          <el-table-column property="createTime" label="创建时间" width="160" />
          <el-table-column fixed="right" label="操作" width="120">
            <template #default="scope">
              <el-button
                type="text"
                @click="editClick(scope.row)"
                style="margin-right: 10px;"
                v-permission="['sys:product:update']"
                >修改
              </el-button>
              <el-dropdown>
                <span class="el-dropdown-link" style="color: #409eff">
                  管理
                  <el-icon class="el-icon--right">
                    <arrow-down />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="productIncomeRateClick(scope.row)" v-permission="['sys:product:update']"
                      >认购金额</el-dropdown-item
                    >
                    <el-dropdown-item @click="productInfoClick(scope.row)" v-permission="['sys:product:update']"
                      >产品说明</el-dropdown-item
                    >
                    <el-dropdown-item @click="productFileClick(scope.row)" v-permission="['sys:product:update']"
                      >资料文件</el-dropdown-item
                    >
                    <el-dropdown-item
                      divided
                      @click="delClick(scope.row)"
                      style="color: crimson"
                      v-permission="['sys:product:delete']"
                      >删除产品</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center;padding-top: 20px;">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="queryForm.limit"
          :current-page="queryForm.page"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>
    <add-or-update-product
      v-if="addDialogVisible"
      v-model="addDialogVisible"
      :id="selectItemId"
      @close="getListData"
    >
    </add-or-update-product>

    <product-income-rate
      v-if="showProductIncomeRate"
      v-model="showProductIncomeRate"
      :id="selectItemId"
    >
    </product-income-rate>

    <product-info
      v-if="showProductInfo"
      v-model="showProductInfo"
      :id="selectItemId"
    >
    </product-info>

    <product-file
      v-if="showProductFile"
      v-model="showProductFile"
      :id="selectItemId"
    >
    </product-file>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { getProductCategoryList } from '@/api/product-category'
import { getProductPage, deleteProductById } from '@/api/product'
import { getSysDictionaryDatasByCodes } from '@/api/sys-dictionary'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ArrowDown } from '@element-plus/icons'
import AddOrUpdateProduct from './components/AddOrUpdateProduct'
import ProductIncomeRate from './components/ProductIncomeRate'
import ProductInfo from './components/ProductInfo'
import ProductFile from './components/ProductFile'
const queryForm = ref({
  page: 1,
  limit: 10,
  sort: 'createTime',
  order: 'desc',
  title: '',
  issuer: '',
  hot: false,
  recommend: false
})

const loading = ref(false)

/**
 * 产品分类数据
 */
const categoryListData = ref([])
// 获取数据的方法
const getCategoryListData = async () => {
  loading.value = true
  categoryListData.value = await getProductCategoryList()
  loading.value = false
}
getCategoryListData()

/**
 * 产品列表数据
 */
const tableData = ref([])
const total = ref(0)
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await getProductPage(queryForm.value)
  tableData.value = result.list
  total.value = result.total
  loading.value = false
}
getListData()

/**
 * 分页数量改变事件
 */
const handleSizeChange = val => {
  queryForm.value.limit = val
  getListData()
}
const handleCurrentChange = val => {
  queryForm.value.page = val
  getListData()
}

/**
 * 选择要操作的列表id
 */
const selectItemId = ref(0)

/**
 * 添加点击事件
 */
const addDialogVisible = ref(false)
const addClick = () => {
  selectItemId.value = 0
  addDialogVisible.value = true
}

/**
 * 修改点击事件
 * @param row
 */
const editClick = row => {
  selectItemId.value = row.id
  addDialogVisible.value = true
}

/**
 * 显示认购金额管理
 */
const showProductIncomeRate = ref(false)
const productIncomeRateClick = row => {
  selectItemId.value = row.id
  showProductIncomeRate.value = true
}

/**
 * 显示产品信息管理
 */
const showProductInfo = ref(false)
const productInfoClick = row => {
  selectItemId.value = row.id
  showProductInfo.value = true
}

/**
 * 显示产品资料管理
 */
const showProductFile = ref(false)
const productFileClick = row => {
  selectItemId.value = row.id
  showProductFile.value = true
}

/**
 * 删除产品
 */
const delClick = row => {
  ElMessageBox.confirm('确认删除 [' + row.title + '] 吗？', '删除', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      await deleteProductById(row.id)
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
      getListData()
    })
    .catch(() => {})
}
</script>

<style scoped></style>
