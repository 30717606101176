<template>
  <div class="editor-container">
    <div id="editor"></div>
  </div>
</template>

<script setup>
import E from 'wangeditor'
import { onMounted, defineProps, defineExpose } from 'vue'
import i18next from 'i18next'
import { useStore } from 'vuex'
import { getProductImgUploadUrl } from '@/api/product'

defineProps({})

const store = useStore()

// Editor实例
let editor
// 处理离开页面切换语言导致 dom 无法被获取
let el
onMounted(() => {
  el = document.querySelector('#editor')
  initEditor()
})

const initEditor = () => {
  editor = new E(el)
  editor.config.height = 150
  editor.config.zIndex = 1
  // 菜单栏提示
  editor.config.showMenuTooltips = true
  editor.config.menuTooltipPosition = 'down'

  // 配置菜单栏，设置不需要的菜单
  editor.config.excludeMenus = ['todo', 'emoticon', 'video', 'code']

  // 上传图片配置
  editor.config.uploadImgServer = getProductImgUploadUrl()
  editor.config.uploadImgMaxSize = 2 * 1024 * 1024 // 2M
  editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图片
  editor.config.uploadImgHeaders = { token: store.getters.token }
  editor.config.uploadFileName = 'file'

  // 国际化相关处理
  editor.config.lang = store.getters.language === 'zh' ? 'zh-CN' : 'en'
  editor.i18next = i18next

  editor.create()
}

// 获取编辑器内容
const getEditorHtml = () => {
  return editor.txt.html()
}
// 设置编辑器内容为空
const setEditorHtml = html => {
  editor.txt.html(html)
}

defineExpose({ getEditorHtml, setEditorHtml })
</script>

<style lang="scss" scoped>
div * {
  user-select: text;
}
.editor-container {
  .bottom {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
