<template>
  <el-dialog
    :model-value='modelValue'
    :close-on-click-modal='false'
    width='60%'
    :title="props.id <= 0 ? '添加产品' : '修改产品'"
    @close='closed'
  >
    <el-form
      ref='basicFormRef'
      :model='basicForm'
      :rules='basicRules'
      :label-width='100'
    >
      <el-row>
        <el-col :span='8'>
          <el-form-item label='产品分类:' prop='categoryId'>
            <el-select
              v-model='basicForm.categoryId'
              placeholder='请选择'
              style='width: 100%'
            >
              <el-option
                v-for='item in categoryListData'
                :key='item.id'
                :label='item.name'
                :value='item.id'
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span='8'>
          <el-form-item label='推荐类别:'>
            <el-checkbox v-model='basicForm.hot' label='热销' />
            <el-checkbox v-model='basicForm.recommend' label='推荐' />
          </el-form-item>
        </el-col>

        <el-col :span='8'>
          <el-form-item label='产品评级:'>
            <el-rate
              style='margin-top: 10px'
              v-model='basicForm.rating'
              allow-half
            />
          </el-form-item>
        </el-col>

        <el-col :span='24'>
          <el-form-item label='产品名称:' prop='title'>
            <el-input v-model='basicForm.title' placeholder='请输入'></el-input>
          </el-form-item>
        </el-col>

        <el-col :span='24'>
          <el-form-item label='产品点评:' prop='comment'>
            <el-input
              v-model='basicForm.comment'
              type='textarea'
              placeholder='请输入'
              rows='2'
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span='24'>
          <el-form-item label='发行进度:' prop='progressDetails'>
            <el-input
              v-model='basicForm.progressDetails'
              type='textarea'
              placeholder='请输入'
              rows='2'
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span='24'>
          <el-form-item label='产品标签:'>
            <el-tag
              v-for='tag in tags'
              :key='tag'
              closable
              :disable-transitions='false'
              @close='tagHandleClose(tag)'
              style='margin-right: 5px'
            >
              {{ tag }}
            </el-tag>
            <el-input
              v-if='tagInputVisible'
              ref='saveTagInput'
              v-model='tagInputValue'
              size='mini'
              @keyup.enter='tagHandleInputConfirm'
              @blur='tagHandleInputConfirm'
              style='width: 120px'
            >
            </el-input>
            <el-button v-else size='small' @click='tagShowInput'
            >添加标签
            </el-button
            >
          </el-form-item>
        </el-col>

        <el-col :span='8'>
          <el-form-item label='发行机构:'>
            <el-input
              v-model='basicForm.issuer'
              placeholder='请输入'
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span='8'>
          <el-form-item label='募集规模:'>
            <el-input v-model='basicForm.scale' placeholder='请输入'></el-input>
          </el-form-item>
        </el-col>

        <el-col :span='8'>
          <el-form-item label='最高收益:' prop='income'>
            <el-input v-model='basicForm.income' placeholder='请输入'
            >
              <template #append>%</template>
            </el-input
            >
          </el-form-item>
        </el-col>

        <el-col :span='8'>
          <el-form-item label='产品期限:' prop='term'>
            <el-input v-model.number='basicForm.term' placeholder='请输入'
            >
              <template #append>月</template>
            </el-input
            >
          </el-form-item>
        </el-col>

        <el-col :span='8'>
          <el-form-item label='抵/质押率:'>
            <el-input
              v-model='basicForm.mortgageRate'
              placeholder='请输入'
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span='8'>
          <el-form-item label='排序号:' prop='sortNumber'>
            <el-input
              v-model.number='basicForm.sortNumber'
              placeholder='越小越靠前'
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span='8' v-if='1 === 0'>
          <el-form-item label='产品图片:'>
            <el-input v-model='basicForm.pic' placeholder='请输入'></el-input>
          </el-form-item>
        </el-col>

        <el-col :span='8'>
          <el-form-item label='项目区域:'>
            <el-cascader
              v-if='showArea'
              v-model='basicForm.area'
              :options='areasProvinceData'
              @change='areaHandleChange'
              style='width: 100%'
              :props='areaProps'
              placeholder="'请选择省 / 市'"
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span='8'>
          <el-form-item label='本期起售日:'>
            <el-date-picker
              v-model='basicForm.saleTime'
              type='date'
              placeholder='请选择'
              style='width: 100%'
              value-format='YYYY-MM-DD'
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span='8'>
          <el-form-item label='产品状态:' prop='status'>
            <el-select v-model='basicForm.status' placeholder='请选择'>
              <el-option label='预售' :value='0' />
              <el-option label='在售' :value='1' />
              <el-option label='已售完' :value='2' />
              <el-option label='已下架' :value='3' />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider></el-divider>
    <el-form ref='formRef' :label-width='100'>
      <el-row>
        <template v-for='attr in productAttrs' :key='attr.id'>
          <el-col :span='8'>
            <el-form-item :label='attr.name'>
              <el-select
                v-model='attr.selectId'
                :multiple='attr.attrType == 1'
                :clearable='attr.attrType == 0'
                placeholder='请选择'
              >
                <el-option
                  v-for='item in attr.children'
                  :key='item.id'
                  :label='item.name'
                  :value='item.id'
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
    <template #footer>
      <span class='dialog-footer'>
        <el-button @click='closed'>取消</el-button>
        <el-button type='primary' @click='onSubmitClick'>保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { createTree } from '@/utils/tree'
import { addProduct, updateProduct, getProductById } from '@/api/product'
import { getProductAttrList } from '@/api/product-attr'
import { getAreasProvince, getAreasCity } from '@/api/areas'
import { getProductCategoryList } from '@/api/product-category'
import { ElMessage } from 'element-plus'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  id: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'id', 'close'])

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
  basicFormRef.value.resetFields()
}

const basicFormRef = ref(null)
// 基本表单信息
const basicForm = ref({
  categoryId: '',
  title: '',
  hot: false,
  recommend: false,
  pic: '',
  progressDetails: '',
  comment: '',
  rating: 0,
  issuer: '',
  scale: '',
  income: '',
  term: '',
  tags: '',
  mortgageRate: '',
  area: '',
  saleTime: '',
  sortNumber: 100,
  province: '',
  city: '',
  status: ''
})

// 基本验证规则
const basicRules = ref({
  categoryId: [
    {
      required: true,
      trigger: 'blur',
      message: '产品分类名称不能为空'
    }
  ],
  title: [
    {
      required: true,
      trigger: 'blur',
      message: '产品标题不能为空'
    }
  ],
  status: [
    {
      required: true,
      trigger: 'blur',
      message: '产品状态不能为空'
    }
  ],
  income: [
    {
      required: true,
      trigger: 'blur',
      message: '产品最高收益不能为空'
    }
  ],
  term: [
    {
      required: true,
      trigger: 'blur',
      message: '产品期限不能为空'
    }
  ],
  progressDetails: [
    {
      min: 0,
      max: 255,
      trigger: 'blur',
      message: '发行进度不能超过255个字符'
    }
  ],
  comment: [
    {
      min: 0,
      max: 500,
      trigger: 'blur',
      message: '产品点评字不能超过500个字符'
    }
  ],
  sortNumber: [
    {
      required: true,
      trigger: 'blur',
      message: '排序号不能为空'
    },
    {
      type: 'number',
      message: '排序号必须是数字'
    }
  ]
})

/**
 * 判断如果id大于0说明编辑，则获取产品信息
 */
const showArea = ref(false)
const getProduct = async () => {
  basicForm.value = await getProductById(props.id)
  // 判断tag是否存在逗号
  if (basicForm.value.tags != null && basicForm.value.tags.length > 0) {
    tags.value = basicForm.value.tags.split(',')
  } else {
    tags.value = []
  }
  // 设置项目区域
  basicForm.value.area = [basicForm.value.province, basicForm.value.city]
  // 设置属性信息
  setTimeout(() => {
    for (const pAttr of productAttrs.value) {
      for (const attr of basicForm.value.attrs) {
        if (pAttr.id === attr.parentId) {
          if (pAttr.attrType === 0) {
            pAttr.selectId = attr.id
          } else if (pAttr.attrType === 1) {
            pAttr.selectId.push(attr.id)
          }
        }
      }
    }
  }, 600)
}
if (props.id > 0) {
  getProduct()
}

/**
 * 属性相关
 */
const productAttrs = ref([])
// 获取属性的方法
const getProductAttrListData = async () => {
  const result = await getProductAttrList()
  productAttrs.value = createTree(result)
}

getProductAttrListData()

/**
 * 产品分类数据
 */
const categoryListData = ref([])
// 获取数据的方法
const getCategoryListData = async () => {
  categoryListData.value = await getProductCategoryList()
}
getCategoryListData()

/**
 * 全国城区数据
 */
const areasProvinceData = ref([])
const getAreasProvinceData = async () => {
  areasProvinceData.value = await getAreasProvince()
  // 解决动态加载级联选择器回显问题
  setTimeout(() => {
    showArea.value = true
  }, 100)
}
getAreasProvinceData()

/**
 * 项目地区选择事件
 */

const areaHandleChange = value => {
  basicForm.value.province = value[0]
  basicForm.value.city = value[1]
}

const tags = ref([])
const tagInputVisible = ref(false)
const tagInputValue = ref('')
const tagHandleClose = tag => {
  tags.value.splice(tags.value.indexOf(tag), 1)
}
const tagShowInput = () => {
  tagInputVisible.value = true
}
const tagHandleInputConfirm = () => {
  const val = tagInputValue.value
  if (val) {
    tags.value.push(val)
  }
  tagInputVisible.value = false
  tagInputValue.value = ''
}

/**
 * 项目地区props配置
 */
const areaProps = {
  value: 'code',
  label: 'name',
  lazy: true,
  async lazyLoad(node, resolve) {
    // value就是选择的省区code
    const { value } = node
    if (!Number.isInteger(value)) {
      return
    }
    const nodes = await getAreasCity(value)
    for (const n of nodes) {
      n.leaf = true
    }
    resolve(nodes)
  }
}

const onSubmitClick = () => {
  // 处理标签数组为逗号分隔
  basicForm.value.tags = tags.value.join(',')
  basicFormRef.value.validate(async valid => {
    if (!valid) return
    const formAttrs = []
    formAttrs.splice(0, formAttrs.length)
    // 遍历追加到数组中
    for (const attr of productAttrs.value) {
      if (attr.attrType === 0 && Number.isInteger(attr.selectId)) {
        formAttrs.push({ id: attr.selectId })
      } else if (attr.attrType === 1 && Array.isArray(attr.selectId)) {
        // 遍历多选框
        for (const id of attr.selectId) {
          formAttrs.push({ id: id })
        }
      }
    }
    basicForm.value.attrs = formAttrs
    if (props.id > 0) {
      await updateProduct(basicForm.value)
    } else {
      await addProduct(basicForm.value)
    }
    ElMessage({
      type: 'success',
      message: '保存成功'
    })
    closed()
  })
}
</script>

<style lang='scss' scoped></style>
