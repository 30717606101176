import request from '@/utils/request'

/**
 * 获取指定产品的资料上传地址
 * @param productId
 * @returns {`/product/file/upload/${string}`}
 */
export const getProductFileUploadUrl = productId => {
  return process.env.VUE_APP_BASE_API + `/product/file/upload/${productId}`
}

/**
 * 获取指定产品的资料列表
 */
export const getProductFileList = productId => {
  return request({
    url: `/product/file/${productId}`,
    method: 'GET'
  })
}

/**
 * 删除产品资料
 * @param id
 * @returns {*}
 */
export const deleteProductFile = id => {
  return request({
    url: `/product/file/${id}`,
    method: 'DELETE'
  })
}
