<template>
  <el-dialog
    :model-value="modelValue"
    :close-on-click-modal="false"
    width="60%"
    title="产品说明管理"
    @close="closed"
  >
    <el-table
      border
      v-loading="loading"
      highlight-current-row
      :header-cell-style="{
        background: '#fafafa',
        color: '#333333',
        padding: '12px 0'
      }"
      :cell-style="{ padding: '4px 0' }"
      :data="infoListData"
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template #default="props">
          <div
            style="padding: 0 10px 0 50px;color: #666666"
            v-html="props.row.content"
          ></div>
        </template>
      </el-table-column>
      <el-table-column property="title" label="标题" />
      <el-table-column
        property="sortNumber"
        label="排序号"
        width="78"
        align="center"
      />
      <el-table-column label="操作" width="120">
        <template #default="scope">
          <el-button type="text" @click="editInfoClick(scope.row)"
            >修改
          </el-button>
          <el-button
            type="text"
            style="color: #ff4d4f"
            @click="delInfoClick(scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-divider></el-divider>
    <el-form ref="infoFormRef" :model="infoForm" :rules="infoRules">
      <el-col :span="18" style="width: 100%;">
        <el-form-item label="" prop="title" style="margin-right: 5px">
          <el-input v-model="infoForm.title" placeholder="请输入介绍标题">
            <template #prepend>介绍标题</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6" style="width: 100%;">
        <el-form-item label="" prop="sortNumber" style="margin-left: 5px">
          <el-input v-model="infoForm.sortNumber" placeholder="请输入排序号">
            <template #prepend>排序号</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="width: 100%;">
        <editor v-model="infoForm.content" ref="editorRef"></editor>
      </el-col>
      <el-col :span="24" style="width: 100%;margin-top: 10px">
        <el-button
          type="primary"
          plain
          @click="addOrEidtInfoClick"
          style="margin-bottom: 20px;width: 100%"
          >{{ showEditInfo ? '确认修改' : '确认添加' }}
        </el-button>
      </el-col>
      <el-col
        :span="24"
        style="width: 100%;text-align: center;"
        v-if="showEditInfo"
      >
        <el-button type="text" @click="hideEditInfoRate">取消</el-button>
      </el-col>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import Editor from './Editor'

import {
  getProductInfoList,
  addProductInfo,
  updateProductInfo,
  deleteProductInfoById
} from '@/api/product-info'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  id: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'id', 'close'])

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
}

const loading = ref(false)

/**
 * 产品介绍表单
 * @type {ToRef<null>}
 */
const infoFormRef = ref(null)
const infoForm = ref({
  productId: props.id,
  title: '',
  content: '',
  sortNumber: '1'
})
const infoRules = ref({
  title: [
    {
      required: true,
      trigger: 'blur',
      message: '产品介绍标题不能为空'
    }
  ],
  content: [
    {
      required: true,
      trigger: 'blur',
      message: '产品介绍内容不能为空'
    }
  ],
  sortNumber: [
    {
      required: true,
      trigger: 'blur',
      message: '排序号不能为空'
    }
  ]
})

/**
 * 获取产品信息列表数据
 * @type {ToRef<*[]>}
 */
const infoListData = ref([])
const getInfoListData = async () => {
  loading.value = true
  infoListData.value = await getProductInfoList(props.id)
  loading.value = false
}
getInfoListData()

/**
 * 添加或修改产品信息
 */
const editorRef = ref(null)
const showEditInfo = ref(false)
const addOrEidtInfoClick = async () => {
  infoForm.value.content = editorRef.value.getEditorHtml()
  if (!showEditInfo.value) {
    // 添加
    infoFormRef.value.validate(async valid => {
      if (!valid) return
      await addProductInfo(infoForm.value)
      ElMessage({
        type: 'success',
        message: '添加成功'
      })
      editorRef.value.setEditorHtml('')
      infoFormRef.value.resetFields()
      getInfoListData()
    })
  } else {
    // 修改
    infoFormRef.value.validate(async valid => {
      if (!valid) return
      await updateProductInfo(infoForm.value)
      ElMessage({
        type: 'success',
        message: '修改成功'
      })
      editorRef.value.setEditorHtml('')
      hideEditInfoRate()
      getInfoListData()
    })
  }
}

/**
 * 修改产品收益信息
 */
const editInfoClick = row => {
  infoFormRef.value.resetFields()
  infoForm.value = JSON.parse(JSON.stringify(row))
  showEditInfo.value = true
  editorRef.value.setEditorHtml(infoForm.value.content)
}

/**
 * 隐藏修改产品信息
 */
const hideEditInfoRate = () => {
  infoFormRef.value.resetFields()
  showEditInfo.value = false
}

/**
 * 删除产品信息点击事件
 */
const delInfoClick = row => {
  ElMessageBox.confirm('确认删除吗？', '删除', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      await deleteProductInfoById(row.id)
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
      getInfoListData()
    })
    .catch(() => {})
}
</script>

<style lang="scss" scoped></style>
