import request from '@/utils/request'

/**
 * 获取指定产品的收益列表
 */
export const getProductIncomeRateList = productId => {
  return request({
    url: `/product/incomeRate/list/${productId}`,
    method: 'GET'
  })
}

/**
 * 获取指定id的收益列表
 */
export const getProductIncomeRateById = id => {
  return request({
    url: `/product/incomeRate/${id}`,
    method: 'GET'
  })
}

/**
 * 添加产品收益
 */
export const addProductIncomeRate = data => {
  return request({
    url: '/product/incomeRate',
    method: 'POST',
    data
  })
}

/**
 * 修改产品收益
 */
export const updateProductIncomeRate = data => {
  return request({
    url: '/product/incomeRate',
    method: 'PUT',
    data
  })
}

/**
 * 删除产品收益
 * @param id 产品收益id
 * @returns {*}
 */
export const deleteProductIncomeRateById = id => {
  return request({
    url: `/product/incomeRate/${id}`,
    method: 'DELETE'
  })
}
